import IconRocket from './icon-rocket.png'
import IconCoinbase from './icon-coinbasesvg.svg'
import IconConnectWallet from './icon-connect-wallet.svg'
import IconMetamask from './icon-metamask.svg'
import IconClose from './icon-close.png'
import IconCloseSurface from './icon-close-surface.png'
import IconChevronBottom from './icon-chevron-bottom.png'
import IconOpenSea from './icon-opensea.png'
import IconCoin from './icon-coin.png'
import IconFilter from './icon-filter.png'
import IconEtherscan from './icon-etherscan.png'

export const icons = {
	'icon-rocket': IconRocket,
	'icon-coinbase': IconCoinbase,
	'icon-connect-wallet': IconConnectWallet,
	'icon-metamask': IconMetamask,
	'icon-close': IconClose,
	'icon-close-surface': IconCloseSurface,
	'icon-chevron-bottom': IconChevronBottom,
	'icon-open-sea': IconOpenSea,
	'icon-coin': IconCoin,
	'icon-filter': IconFilter,
	'icon-etherscan': IconEtherscan,
}